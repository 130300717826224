.wrapperLoader-dg {
  z-index: 3;
  background: rgba(255,255,255,0.95) !important;
}
.duckHead {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;

  -webkit-animation-name: spinner;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 1s;
  animation-name: spinner;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 0s;
  animation-duration: 4s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform-origin: center center;
}

/* WebKit and Opera browsers */
@-webkit-keyframes spinner {
  0%
  {
    -webkit-transform: rotateY(0deg);
    opacity: 1;
  }
  10% {
  opacity: 0.5;
  }
  20% {
    -webkit-transform: rotateY(-360deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(-360deg);
  }
} /* all other browsers */
@keyframes spinner {
  0% {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    opacity: 1;
  }
  10% {
    opacity: 0.5;
  }
  20%
  {
    -moz-transform: rotateY(-360deg);
    -ms-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
    opacity: 1;
  }
  100%
  {
    -moz-transform: rotateY(-360deg);
    -ms-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
  }
}

.profile-main-loader{
  margin: 0 auto;
  top: 40vh;
  position: fixed;
  text-align: center;
  width: 100%;
}

.profile-main-loader .loader {
  position: relative;
  margin: 0px auto;
  width: 200px;
  height:200px;
}
.profile-main-loader .loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.circular-loader {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
}

.loader-path {
  stroke-dasharray: 150,200;
  stroke-dashoffset: -10;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
@-webkit-keyframes color {
  0% {
    stroke: $dt-red;
  }
  40% {
    stroke: $dt-red;
  }
  66% {
    stroke: $dt-red;
  }
  80%, 90% {
    stroke: $dt-red;
  }
}
@keyframes color {
  0% {
    stroke: $dt-red;
  }
  40% {
    stroke: $dt-red;
  }
  66% {
    stroke: $dt-red;
  }
  80%, 90% {
    stroke: $dt-red;
  }
}

.overflowLoader {
  max-height: 100vh;
}
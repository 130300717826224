/*
Struture
Inspiré du Pattern 7-1   https://sass-guidelin.es/fr/#architecture
*/
@charset 'utf-8';

:root {
    --faq-primary-color: #e92328;
} 

@import 'styles/abstracts/_functions';
@import 'styles/abstracts/_mixins';
@import 'styles/abstracts/_variables';
@import 'styles/abstracts/_animations';

@import 'styles/base/_typography';
@import 'styles/base/_reset';
@import 'styles/base/_utilities';

@import 'styles/pages/_home';
@import 'styles/pages/_faq';
@import 'styles/pages/_cart';
@import 'styles/pages/_category';
@import 'styles/pages/_produit';
@import 'styles/pages/_passwordReset';
@import 'styles/pages/_404';

 @import 'styles/themes/_theme-default';
/* @import 'styles/themes/_theme-light'; */
/* en cours @import 'styles/themes/_theme-dark'; */

@import 'styles/components/_buttons';
@import 'styles/components/_loaderFile';
@import 'styles/components/_navigation';
@import 'styles/components/_loader';
@import 'styles/components/_pagination';
@import 'styles/components/_pricesTable';
@import 'styles/components/_productCard';

@import 'styles/layout/_main';
@import 'styles/layout/_header';
@import 'styles/layout/_footer';





.page404 {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .sentenceContainer404 {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 40px 100px;
  }
}
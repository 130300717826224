.slider-wrapper {
    .slick-prev {
      left: 0px;
      z-index:2;
    }
    .slick-next {
      right: 0px;
      z-index:2;
    }
  .slick-prev, .slick-next {
    height: auto;
    background:white;
    width: auto;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    color: $dt-dark-grey;
  }
  .slick-next:hover, .slick-prev:hover {
    cursor: pointer;
    opacity: 0.8;
    color: initial;
    background: scale-color($dt-white, $lightness: 50%);
  }

  .slick-next:before,.slick-prev:before {
    content: none;
  }
    .slick-active svg {
        color: $dt-red;
    }
    .slide-content {
      font-size: 36px;
      &.slide-24h {
       @include radial-gradient(#402d52,  shade(#402d52,40%));
      }
      &.slide-new-product {
        @include radial-gradient(#1387f3,  shade(#1387f3,40%));
      }
      &.slide-sabuncu {
        @include radial-gradient(#ca2074,  shade(#ca2074,40%));
      }
      &.slide-crazy-product {
        @include radial-gradient(#2ae2ca,  shade(#2ae2ca,40%));
      }
    }
    .slick-dots {
      background-color: transparent !important;
      bottom: 0;
      padding: 10px !important;

      @include respond-to('large') {
        bottom: 20px;
        padding: 30px !important;
      }

      ul {
        padding: 0;
      }
    }
  &:hover {
      .slick-prev, .slick-next {
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
      }
  }
}

.info-activite {
  background: scale-color($dt-light-grey, $lightness: 50%);
  @include font-size(18);
  padding: 50px 0;
}

.bestsellers {
  .slick-prev {
    left: -20px;
    @include respond-to('medium') {
      left: -37px;
    }
    @include respond-to('large') {
      left: -50px;
    }
  }
  .slick-next {
    right: -20px;
    @include respond-to('medium') {
      right: -37px;
    }
    @include respond-to('large') {
      right: -50px;
    }
  }
  .slick-prev:hover, .slick-next:hover {
    background: scale-color($dt-red, $lightness: 0%);
    &:before {
      color: $dt-white !important;
    }
  }
}

.home-title-main {
  @include respond-to('medium') {

  }
}

.encartHome {
  h4 {
    @include font-size(18);
  }
  .overflow-hidden + span {
    text-transform: uppercase;
    @include font-size(16);
    opacity: 0.7
  }
  .prix {
    color: $dt-white;
  }
  .prix .white {
    @include font-size(24);
    @include bold();
  }
  .background {
    background-size: contain !important;
    @include box-shadow(0, 0, 10px, tint($dt-dark-grey, 80%), false);
  }
  &:hover .background {
    @include box-shadow(0, 0, 10px, tint($dt-dark-grey, 10%), false);
  }
}
#encartHome1 .background {
  background: linear-gradient(50deg, scale-color(#2f95f4, $lightness: -10%) 30%, #2f95f4 0%);
}
#encartHome3 .background {
  background:linear-gradient(50deg, scale-color(scale-color(scale-color($dt-red, $lightness: 15%), $lightness: -20%), $lightness: -10%) 30%, scale-color($dt-red, $lightness: 15%) 0%);
}
#encartHome2 .background{
  background:  linear-gradient(50deg, scale-color(scale-color(scale-color(#23FAF1, $lightness: -25%), $lightness: -20%), $lightness: -10%) 30%, scale-color(#23FAF1, $lightness: -25%) 0%);
}

#encartHome4 .background {
  background:  linear-gradient(50deg, scale-color(scale-color(scale-color(#f07e26, $lightness: 15%), $lightness: -20%), $lightness: -10%) 30%, scale-color(#f07e26, $lightness: 0%) 0%);
}

.logoDgHome {
  max-width: 400px !important;
  margin: 0 auto;
}

.slick-arrow {
  // display: none;
  @include respond-to('large') {
    display: block;
  }
}

.bestsellers  {
  .slick-track {
    // transform: translate3d(-375px, 0px, 0px) !important;
  }

  .slick-track > div {
    margin: 0;
    border-bottom: 3px solid white;
    padding-bottom: 10px;

    &:hover {
      border-bottom: 3px solid #dee2e6;
    }
    a:hover {
      text-decoration: none;
    }
    /* @include respond-to('medium') {
      width: 126px !important;
      margin: 0 20px;
    }
    @include respond-to('large') {
      width: 139px !important;
    }
    @include respond-to('extra-large') {
      // width: 248px !important;
      width: 134px !important;
      // margin: 0;
    }
    @include respond-to('extra-extra-large') {
      width: 156px !important;
    } */
  }

  .slick-arrow {
    display: block;
  }

  .slick-dots {
    bottom: -30px;
  }
}

.h2Home {
  line-height: 27px;
  text-transform: initial;
}

.mh140 {
  max-height: 140px!important;
}

.white-space-nowrap {
  white-space: nowrap
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.opac30 {
  opacity: 0.3;
}
.opac40 {
   opacity: 0.4;
 }

.opac60 {
  opacity: 0.6;
}

.home-big {
  min-height: 140vw;
  @include respond-to('small') {
    min-height: 50vw;
  }
  margin-bottom: 20px;
  @include respond-to('large') {
    min-height: unset;
    margin-bottom: unset;
  }
  & .position-relative {
    min-height: 140vw;
    @include respond-to('small') {
      min-height: 50vw;
    }
    &.home-big-1 {
      background: url('/images/home-big-1.jpg') no-repeat;
    }
    &.home-big-2 {
      background: url('/images/home-big-2.jpg') no-repeat;
    }
    &.home-big-3 {
      background: url('/images/home-big-3.jpg') no-repeat;
    }
    &.home-big-4 {
      background: url('/images/home-big-4.jpg') no-repeat;
    }
    background-size: cover !important;
    @include respond-to('large') {
      min-height: 600px;
    }
  }
  & .home-big-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .3);
    right: 0;
    width: 100%;
    padding: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    @include respond-to('small') {
      min-height: 35vh;
      height: 100%;
    }
    @include respond-to('large') {
      height: unset;
      min-height: unset;
      display: block;
      bottom: 0;
      top: unset;
    }

    .home-big-titre, .home-big-description {
      color: $dt-white;
      text-transform: none;
    }

    .home-big-description {
      min-height: unset;
      @include respond-to('large') {
        min-height: 6.7rem;
      }
      @include respond-to('medium') {
        min-height: 8.7rem;
      }
    }

    .home-bg-content {
      background: rgba(0, 0, 0, 0.4);
      padding: 20px;
    }
  }
  & .home-big-wrapper-light {
    background: $dt-light-grey;

    .home-big-titre, .home-big-description {
      color: $dt-black;
    }
  }
}

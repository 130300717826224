// Header
$navigation-bg : transparent;
$navigation-bg-hover : $dt-red;
$navigation-color-hover : $dt-white;
$navigation-color : $dt-black;
$navigation-color-mobile :  $dt-white;
$navigation-border :  transparent;
$navigation-border-top :  transparent;
$footer-bg : $dt-black;
$footer-color :  $dt-white;
$hasBgImgFooter : true;

$second-navigation-bg : $dt-light-grey;
$second-navigation-link-color : $dt-dark-grey;

$nav-icon-bg : transparent;
$nav-icon-border-color : $dt-white;
$nav-icon-color: $dt-white;

$main-search-container-bg: $dt-light-grey;
$dt-baseline-color : $dt-white;
$dt-baseline-quotation-color : $dt-red;
$short-menu-icons: $dt-light-grey;



$body-bg : $dt-white;
$body-color : $dt-black;

@include font-face('Carter One', '/fonts/carterone-regular-webfont');
@include font-face('Open Sans Bold', '/fonts/opensans-bold-webfont');
@include font-face('Open Sans Regular', '/fonts/opensans-regular-webfont');

body {
  font-family: 'Open Sans Regular', 'Arial', 'sans-serif';
  @include font-size(16);
}


h1 {
  font-family: 'Open Sans Bold';
  @include font-size(25);
  @include respond-to('large') {
  @include font-size(30);
  }
}

h2, .h2 {
  font-family: 'Open Sans Regular';
  @include font-size(16);
  color: $dt-dark-grey;
  text-transform: uppercase;
  @include respond-to('large') {
    @include font-size(25);
  }
}

h3, .h3 {
  font-family: 'Open Sans Regular';
  @include font-size(14);
  @include respond-to('large') {
    @include font-size(20);
  }
}

.carterOne {
  font-family: 'Carter One' !important;
}

.openSansRegular {
  font-family: 'Open Sans Regular';
}

.openSansBold {
  font-family: 'Open Sans Bold' !important;
}

.font50 {
  @include font-size(50);
}

.font40 {
  @include font-size(40);
}
.font35 {
  @include font-size(35);
}
.font30 {
  @include font-size(30);
}
.font25 {
  @include font-size(25);
}
.font20 {
  @include font-size(20);
}
.font18 {
   @include font-size(18);
 }
.font16 {
  @include font-size(16);
}
.font15 {
  @include font-size(15);
}
.font14 {
  @include font-size(14);
}
.font12 {
  @include font-size(12);
}
.font10 {
  @include font-size(10);
}
.supSize {
  font-size: .5em;
}
.htSize {
   font-size: .3em;
 }
.lineheight-1 {
  line-height: 1;
}
.text-decoration-throught {
  text-decoration: line-through;
}
.noTextDecoration {
  text-decoration: none !important;
}


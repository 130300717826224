.loaderFile {
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader-anim {
    background: #f2f2f2;
    border-radius: 50px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    opacity: 1;
    animation: hideshow2 2s  cubic-bezier(0.1, 0, 0.2, 1.51) infinite;
    .theMaskBottom {
      opacity: 0;
      animation: moveBot 2s ease infinite forwards;
    }
    .theMaskTop {
      opacity: 1;
      animation: moveTop 2s ease  infinite forwards;
    }
  }
@keyframes moveTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
  19% {
    opacity: 0;
  }
  20% {
    -webkit-transform: translateY(15px);
    -ms-transform: translateY(15px);
    transform: translateY(15px);
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes moveBot {
  0% {
    opacity: 1;
  }
  19% {
    opacity: 1;
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
  75% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
  77% {
    -webkit-transform: translateY(15px);
    -ms-transform: translateY(15px);
    transform: translateY(15px);
  }
}

@keyframes hideshow2 {
  0% {
    opacity: 0;
    -moz-transform: none;
    -webkit-transform: none;
    transform:none;
  }
  3% {
    -moz-transform: none;
    -webkit-transform: none;
    transform:none;
  }
  20% {
    opacity: 1;
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform:rotate(180deg);
  }
  98% {
    opacity: 1;
  }
  99% {
    opacity: 1;
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform:rotate(180deg);

  }
  100% {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform:rotate(180deg);
  }
}
}

.sliderImgProduct {
  .swiper-slide {
    align-self: center;
  }
}
.sliderImgProduct {
  display: flex;
  flex-direction: column-reverse;
  max-height: 50vh;
  .swiper-slide img {
    height: 30vh;
  }
  @include respond-to('medium') {
    max-height: unset;
    .swiper-slide img {
      height: auto;
    }
  }
  @include respond-to('extra-large') {
    margin-right: 30px;
  }
  .swiper-pagination {
    margin: 10px 0;
    position: static;
    display: flex !important;
    justify-content: center;
    width: 100%;
    min-height: 24px;
    @include respond-to('large') {
      min-height: 72px;
    }
    span.bullet-custom {
      width: 80px;
      height: auto;
      margin: 0 10px;
      padding: 5px;
      outline: 2px solid #f1f1f1;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      &.swiper-pagination-bullet-active {
        outline: 2px solid $dt-black;
      }
    }
    span.bullet-mobile {
      margin-right: 10px;
      img {
        opacity: .3;
        height: 20px;
      }
      &.swiper-pagination-bullet-active {
        &:before {
          content: ' ';
          background: $dt-red;
          border-radius: 20px;
          display: inline-block;
          vertical-align: middle;
          width: 18px;
          height: 18px;
        }
        img {
          display:none;
        }
      }
    }
  }
}

.customizationModalContainer, .styles_modal__gNwvD.customizationModalContainer  {
  max-width: 100vw;
  max-height: 100vh;
  padding:0;
  min-width: auto;
}
.styles_modal__gNwvD.zoomModalContainer, .zoomModalContainer {
  max-width: 90vw;
  max-height: 90vh;
  padding:0;
  min-width: auto;
}
.zoomModalContainer .img-fluid {
  max-height: 85vh;
  max-width: 85vw;
}

.finesse-img {
  position: absolute;
  left: 0;
  opacity:1;
  max-height: 60vh;
  animation: finesseAnimation 1s infinite;
}

@keyframes finesseAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.opac0 {
  opacity: 0 !important;
}

.opac80 {
  opacity: .8;
}

.loader-upload {
  width: 1px;
  background-color: $dt-red;
  transition: all 250ms linear;
}

.rounded-grey-icon {
  display: flex;
  padding: .5rem;
  justify-content: center;
  align-items: center;
  background-color: #F2F2F2;
  width: 64px;
  height: 64px;
  border-radius: 100%;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes revert-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }

  @keyframes arrow-up {
    0% {
      opacity: 1;
      transform: translateY(65%);
    }
    60% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(0);
    }
  }

  .large-wheel {
    transform-origin: 62% 37%;
    animation: spin 5s linear 0s infinite;
  }
  .little-wheel {
    transform-origin: 19% 79%;
    animation: revert-spin 3s linear 0s infinite;
  }
  .arrow-upload {
    animation: arrow-up 1.25s linear 0s infinite;
  }
}

.h40px {
  height: 40px!important;
  min-height: 40px!important;
}

.surfaceTotale {
  width: 100%;
  text-align: center;
  bottom: -25px;
}
.customerUploadContainer {
  width: 100%;
  justify-content: center;
  @include respond-to('extra-large') {
    width: 125px;
  }
}

.surfaceTotale {
  position: static;
  @include respond-to('large') {
    position: absolute;
  }
}

@media only screen and (min-width: 899px) and (max-width: 991px) {
.btnModifierDimensions  {
    width: 100%;
    }
}
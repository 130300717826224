header {
  z-index: 4;
  top: 0;
  right: 0;
  left: 0;
  height: 52px;
  transition: ease-out 0.3s;
  @include respond-to('large') {
    height: 120px;
  }
}

.nbProductsCart {
  background: $dt-red;
  top: -2px;
  right: 0px;
  @include respond-to('medium') {
    right: -10px;
  }
}
.blocMenuPanier:hover, .blocMenuCompte:hover {
  outline: 1px solid $dt-black;
}

.resultsSearch {
  max-height: 80vh;
  padding-top: 20px;
  @include respond-to('medium') {
    max-height: 82vh;
  }
  top: 90%;
  border: .8px solid #80bdff;
  border-top: none;
  border-radius: .25rem;
  max-width: 100%;
  min-width: 100%;
  overflow: auto;
  border-top-left-radius: 0;
}

.zIndex4 {
  z-index: 4 !important;
}

.zIndex5 {
  z-index: 5 !important;
}

.overlaySuggest {
  z-index: 3;
  background-color: rgba(0,0,0,.6);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.top0 {
  top: 0 !important;
}
.moreResultsSearch a {
  display:block;
  @include respond-to('medium') {
    display:initial;
  }
}

.banner-info {
  display: flex;
  background-color: $dt-red;
  color: white;
  justify-content: space-between;
  letter-spacing: normal;
  padding-left: 1rem !important;
  @include respond-to('large') {
    background-color: $dt-black;
  }
  &.close-banner {
    display: none;
  }

  .icon-close {
    position: relative;
    font-size: 30px;
  }

  @include respond-to('large') {
    justify-content: center;

    .icon-close {
      position: absolute;
      top: 0;
      right: 6px;
      bottom: 0;
      align-items: center;
      font-size: 24px;
    }
  }
}

.dt-overlay-menu-mobile {
  opacity: 0.5;
  background-color: white;
  position: fixed;
  width: 100vw;
  top: 0;
  left: -100vw;
  bottom: 0;
  transition: all .25s ease;
  transition-delay: 0s;

  &.open {
    left: 0;
    transition-delay: 0.25s;
  }
}

.logoHeader {
  height: 50px;
}

.main-navigation-mobile {
  .navbar-collapse {
    position: fixed;
    left: -80vw;
    top: 0;
    background: $dt-black;
    height: 100vh;
    min-width: 80vw;
    max-width: 80vw;
    overflow: scroll;
    @include respond-to('small') {
      min-width: 50vw;
    }
    @include respond-to('medium') {
      min-width: 30vw;
    }
    .startSubMenuMobile {
      margin-top: 40px;
    }
    .nav-item {
      svg:first-child {
        margin-right: 20px !important;
      }
      svg {
        min-width: 25px;
      }
      width: 100%;
      border-right: none !important;
      &:not(.noBorderTopMenu) {
        border-top: 2px dotted shade($dt-white, 70%);
      }
      &.categoriesMenuFirst {
        border-top: 1px solid shade($dt-white, 70%);
      }
      a {
        line-height: 1em;
      }
      &:hover, & a:hover, & a:hover span {
        background: $navigation-bg-hover;
        color: $navigation-color-hover !important;
        text-decoration: none;
        & svg {
          color: white !important;
        }
      }
    }
    .blocMenuPanier, .blocMenuCompte {
      padding: 0 !important;
    }
  }
  .navbar-collapse.show.menu-collapsed {
    height: 100vh !important;
    transition: all .25s ease;
    left: 0;
  }
  .navbar-collapse.menu-not-collapsed {
       height: 100vh !important;
       transition: all .35s ease;
    left: -80vw;
  }
  .navbar-collapse.show {
    left: 0;
  }
  .dropdown:not(.select-language-container) {
    .dropdown-menu {
      transform: scaleY(-1);
      transform-origin: top;
      transition: transform 0.26s ease;

      &.show {
        transform: scaleY(1) translate3d(0px, 0px, 0px) !important;
        position: static !important;
        background: shade($dt-white, 75%);
        //@include box-shadow(0, 3px, 2px, $dt-black, true);
        box-shadow: inset 0 5px 8px -5px rgba(0, 0, 0, 0.8);

        .dropdown-item {
          @include font-size(14);
        }
      }
    }
  }
  .select-language-container button:not(.dropdown-item) {
    color: $dt-white !important;
    padding: 0 !important;
  }
  .nav-item:first-child {
       border-left: none !important;
  }
  .nbProductsCart {
    right: 5px;
  }
}
.main-navigation-fixed-top {
  @include box-shadow(0, 0, 10px, rgba(0,0,0,0.9), false);
}
.main-navigation {
  @include font-size(14);
  .nav-item:first-child {
    border-left: 1px solid  $navigation-border;
  }
  .nav-item:not(.noBorderMenu) {
    border-right: 1px solid  $navigation-border;
  }
  .nav-item {
    svg:first-child {
      margin-right: 10px !important;
    }
    a span {

      border-bottom : 2px solid transparent;
    }
    a span {
      border-bottom : 1px solid transparent;
    }
    a:hover span {
      border-bottom : 1px solid $dt-red;
    }
    &:hover, & a:hover, & a:hover span,  & a.active {
      text-decoration: none;
      @include respond-to('large') {
        color: $dt-black;
      }
    }
  }
  .dropdown-menu {
    margin: 0;
    border-radius: 0;
  }
  .spanMenu {
    min-height: 30px;
    line-height: 30px;
  }
}

.wrapper-dt-collapsed-menu {
  height: 100vh !important;
  overflow: hidden;
}
.dt-collapsed-menu {
  transition: opacity .35s 300ms ease;
  opacity: 0.3;
}

.navMenu {
  background-color: $navigation-bg;
  color: $navigation-color;
  border: 1px solid $navigation-border-top;
  border-bottom: none;
  a {
    color: $navigation-color-mobile;
    @include respond-to('large') {
      color: $navigation-color;
    }
  }
}
.baseLineHeadWrapper {
  max-width: 500px;
}
.baseLineHeadTitle {
  letter-spacing: 0.01em;
}

.justLink {
  text-decoration: none;
  border-bottom: 1px solid transparent;
  &:hover {
    text-decoration: none;
    border-bottom: 1px solid $dt-red;
  }
}
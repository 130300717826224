.checkBoxErrorPassword input{
  width: auto;
}

.checkBoxErrorPassword .checked{
  color: green;
}

.passWordProgressBar {
  position: relative;
  height: 10px;
  border-radius: 50px;
  border: 1px solid #eee;
  width: 65%;
}

.passWordFiller {
  height: 100%;
  border-radius: inherit;
  transition: width .2s ease-in;
}

.blocPasswordInfos {
  max-width: 328px;
}

.passWordProgressBarBlock {
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  font-size: 12px;
}

.checkBoxErrorPassword {
  font-size: 12px;
  height: 20px;
}

.font14 {
  font-size: 14px;
}

.eyePasswordForm, .resetPasswordForm {
  position: absolute;
  right: 20px !important;
  top: 11% !important;
  cursor: pointer;
}

.resetPasswordForm {
  right: 50px !important;
  top: 20% !important;
}

.resetPasswordForm:focus {
  display: block;
}

.eyePasswordForm:active, .eyePasswordForm:hover, .resetPasswordForm:active, .resetPasswordForm:hover {
  color: black;
}

.eyePasswordForm, .resetPasswordForm {
  color: rgb(153, 153, 153);
}

.inputPasswordForm {
  position: relative;
  max-width: 310px;
}

.checkBoxErrorPassword label {
  vertical-align: middle;
  padding-left: 10px;
}

.margin-top-1 {
  margin-top: 10px;
}

.resetDivPasswordForm {
  min-width: 295px;
}

.h35 {
  height: 35px;
}

.blocPassword {
  max-width: 340px;
}

.btnValidPasswordResetForm {
  padding: 5px;
}

.w100 {
  width: 100%;
}

.pa05 {
  padding: 5px;
}

.capsLockInfos {
  font-size: 12px;
  font-style: italic;
  color: grey;
}

.inputPasswordFormProfil {
  max-width: 220px;
}

.blocPasswordInfosProfil {
  max-width: 230px;
}
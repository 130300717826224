.containerCart {
  @include respond-to('extra-large') {
    max-width: 1200px;
  }
}

@media print {
  #btn-livechat {
    display: none;
  }
  .footer-bottom {
    display: none;
  }

  .footer-top {
    border: none !important;
    position: relative;
    bottom: 0;
    width: 100%;
    .avantage {
      border: 1px solid $dt-light-grey !important;
    }
    .avantage:last-of-type {
        border-right: 1px solid $dt-light-grey !important;
    }
  }

  div.main {
    top: -150px;
  }

  .flexReducFranco {
    display: none;
  }

  .container-dg {
    padding-bottom: 0 !important;
  }
  .blocPaypalExpress, .printPUht {
    display: none;
  }

  .cartItemTitle a {
    text-decoration: none !important;
  }
  .contentItemPrice > div > div {
    display: none !important;
  }
  .available {
    position: relative;
  }
  .available > div {
    position: absolute;
    top: 30px;
  }
  .available{
    max-width: 12% !important;
    flex: auto !important;
  }
  .printInfosArticle {
    max-width: 35% !important;
    flex: auto !important;
  }
  .printPU > div{
    max-width: max-content;
  }
}

.logo-dg-printCart {
  display: none;
  @media print {
    display: block;
    text-align: center;
    height: 200px;
    position: relative;
    top: -100px;
  }
}
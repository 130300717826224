@use "sass:math";

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

// Usage https://w3bits.com/sass-mixins/
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity = $opacity-ie); //IE8
}

@mixin inscrustation($urlImg, $color) {
  background-image: url($urlImg);
  background-color: $color;
  background-blend-mode: multiply;
}

@mixin bold($weight: null){
  font-family: 'Open Sans Bold';
  font-weight: $weight;
}

@mixin radial-gradient($from, $to) {
  background: -moz-radial-gradient(center, circle cover, $from 0%, $to 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $from), color-stop(100%, $to));
  background: -webkit-radial-gradient(center, circle cover, $from 0%, $to 100%);
  background: -o-radial-gradient(center, circle cover, $from 0%, $to 100%);
  background: -ms-radial-gradient(center, circle cover, $from 0%, $to 100%);
  background: radial-gradient(center, circle cover, $from 0%, $to 100%);
  background-color: $from;
}

@mixin font-size($size, $base: 16) {
  font-size: $size; // fallback for old browsers
  font-size: math.div($size, $base) * 1rem;
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: woff ttf svg) {
  $src: null;
  $extmods: (
          eot: "?",
          svg: "#" + str-replace($name, " ", "_")
  );
  $formats: (
          otf: "opentype",
          ttf: "truetype"
  );
  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }
  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
    font-display: fallback;
  }
}

@mixin truncate($width: 100%) {
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin price() {
  color: $dt-red;
  @include bold;
}

@mixin priceCustomization() {
  color: scale-color($dt-black, $lightness: 40%);
}

@mixin rounded($radius: 0.5em) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin linkInline() {
  color: $dt-red;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: $dt-red;
  }
}
// Palette de couleurs DuckTransfer
$dt-black: rgb(0, 0, 0);
$dt-red: rgb(233, 35, 40);
$dt-dark-grey: rgb(64, 64, 64);
$dt-light-grey: rgb(241, 241, 241);
$dt-grey: rgb(180, 180, 180);
$dt-white: rgb(255,255,255);
$dt-green: rgb(124,187,35);


// Points de rupture correspondant à la grille de Bootstrap v4 + ajout 320px
// https://getbootstrap.com/docs/4.3/layout/grid/
$breakpoints: (
  'zero-point': (min-width: 1px),
  'extra-extra-small': (max-width: 374px),
  'extra-small': (min-width: 375px),
  'small': (min-width: 576px),
  'max-medium': (max-width: 767px),
  'medium': (min-width: 768px),
  'large': (min-width: 992px),
   'max-large': (max-width: 1199px),
  'extra-large': (min-width: 1200px),
  'extra-extra-large': (min-width: 1600px),
);
footer {
  background: $dt-white;
}

.navFooter {
  $hasBgImgFooter: false !default;
  background-color: $footer-bg;
  @if $hasBgImgFooter {
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23505051' fill-opacity='0.6' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  }
  a {
    @include respond-to('max-medium') {
      padding: 0.8rem 0;
    }
  }
}



.border-footer {
  border: 4px dotted #333;
}

.footer {
  color: $footer-color;
  z-index: 0;
  a, h2 {
    color: $footer-color;
  }

  &-top {
    background: $dt-white;
    color: $dt-black;
    border-top: 1px solid $dt-light-grey;
    line-height: 1.3em;
  }

  &-bottom {
    background: $footer-bg;

    a {
      height: 48px;
      @include respond-to('medium') {
        height: auto;
      }
    }
  }
  .renderLinksFooter a {
    color: $footer-color !important;
  }

  .benefits-shadow {
    @include box-shadow(0, 1rem, 12rem, rgba(255,255,255, 1), false);
    @include rounded(3.3em);
    border: 0.4rem inset $dt-red;
  }



}



button, a {
  &.btn-dt-primary, &.btnSubmitCart, &.buttonPrimary  {
    background: $dt-red;
    background-color: $dt-red !important;
    border-color: $dt-red;
    color: $dt-white;
    border-radius: 0.25rem;
    padding: 1rem;
    &:hover {
      background: shade($dt-red, 10%) radial-gradient(circle, transparent 1%, shade($dt-red, 10%) 1%) center/15000% !important;
      color: $dt-white;
      box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.15);
    }
    &:active, &:focus {
      background-color: $dt-red !important;
    }
    &:disabled, &.disabled  {
      opacity: 1;
      background: scale-color($dt-light-grey, $lightness: -10%) !important;
      cursor: not-allowed;
      border-color: scale-color($dt-light-grey, $lightness: -10%)  !important;
    }
  }
  &.btn-dt-secondary {
    background: transparent;
    border-color: $dt-black;
    color: $dt-black;
    border-radius: 0.25rem;
    padding: 1rem;
    opacity: 1;
    @include bold;

    &:active, &:focus,  &:hover {
      background: shade($dt-red, 10%) radial-gradient(circle, transparent 1%, shade($dt-red, 10%) 1%) center/15000% !important;
      color: $dt-white;
      border-color: $dt-red !important;
    }

    &:disabled, &.disabled {
      opacity: 1;
      background: scale-color($dt-light-grey, $lightness: -10%);
      cursor: not-allowed;
    }

    &.btn-dt-success {
      &:hover, &:active, &:focus {
        background: $dt-green;
        color: $dt-white;
        border-color: $dt-green;
        background: shade($dt-green, 10%) radial-gradient(circle, transparent 1%, shade($dt-green, 10%) 1%) center/15000%;
      }
    }
    &.btn-dt-error {
      &:hover, &:active, &:focus {
        background: $dt-red;
        color: $dt-white;
        border-color: $dt-red;
        background: shade($dt-red, 10%) radial-gradient(circle, transparent 1%, shade($dt-red, 10%) 1%) center/15000%;
      }
    }
  }
}

.btnSubmitCart {
  padding: 0.8em 2em;
  width: 100%;
  margin-bottom:  0em;
  margin-top:  2em;
  @include respond-to('medium') {
    padding: 0.8em 5.8em;
    width: auto;
    margin-bottom:  1em;
    margin-top:  0em;
  }
}

.buttonPrimary {
  background-color: $dt-red;
  color: $dt-white;
  font-family: 'Roboto Regular';
  padding: 1em 2em;

  &:hover {
    background: shade($dt-red, 30%) radial-gradient(circle, transparent 1%, shade($dt-red, 30%) 1%) center/15000%;
  }

  &:active {
    background-color: $dt-red;
  }
  &:disabled, &.disabled  {
    opacity: 1;
    background: scale-color($dt-light-grey, $lightness: -10%);
    cursor: not-allowed;
  }
}

.btn-filter-videos {
  background-color: $dt-light-grey;
  border-radius: 3rem;
  border: none;
  @include font-size(12);
  padding: 6px 12px;
  @include respond-to('large') {
    @include font-size(14);
    padding: 12px 24px;
  }
  &.active {
    background-color: $dt-red;
    color: white;
  }
}


.blocPaypalExpress  {
  margin-top: 0 !important;
  width: 100% !important;
  margin-bottom:  2em;
  @include respond-to('medium') {
    max-width: 350px;
    margin-bottom:   2em;
  }
  display: flex;
  justify-content: flex-end;
}
.blocPaypalExpress .flex-grow-1,
.blocPaypalExpress > div {
  width: 100% !important;
  @include respond-to('medium') {
    width: inherit;
  }
}
.containerCartDT .blocPaypalExpress > div:first-child {
  flex-grow: 1;
  @include respond-to('medium') {
    flex-grow: unset;
  }
}
.productCard-container {
  width: 50%;

  @include respond-to('medium') {
      width: 33%;
  }
  @include respond-to('medium') {
      width: 33%;
    }
  @include respond-to('extra-large') {
    width: 25%;
  }
  .productCard .productCard-wrapper-img {
    padding: 10px;
    display: block;
    @include box-shadow(0, 0px, .9px, tint($dt-dark-grey, 30%), false);
    &:hover {
      @include box-shadow(0, 1px, 5px, tint($dt-dark-grey, 60%), false);
    }
  }
}
.productCard-container.productCard-container-transferts {
  @include respond-to('extra-large') {
    width: 33%;
  }
}
.descriptionProductTransfer {
  white-space: break-spaces;
  @include respond-to('extra-large') {
  min-height: 125px;
  }
}

@mixin lastElementOfLigne($n) {
  $sizes: 2, 3, 4;
  @each $size in $sizes {
    .productCard-container:nth-of-type(#{$size}n) {
      .productCard {
        padding-right: 1.5rem !important;
      }
    }
  }
  .productCard-container:nth-of-type(#{$n}n) {
    .productCard {
      padding-right: 0 !important;
    }
  }
}

.page-category {
  @include respond-to('zero-point')  {
      @include lastElementOfLigne(2);
  }
  @include respond-to('medium') {
    @include lastElementOfLigne(3);
  }
  @include respond-to('extra-large') {
    @include lastElementOfLigne(4);
  }
}
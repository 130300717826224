.page-item {
  a, button {
    color: $dt-black;
  }
  &.active {
    .page-link {
    background-color: $dt-red;
      border-color: $dt-red;
    }
  }
}
.page-category {
  min-height: 600px;
  .collapse-css-transition {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .collapse-css-transition {
    position: relative;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
    &--gradient {
      &:after {
        pointer-events: none;
        transition: opacity 300ms;
        will-change: opacity;
        opacity: 1;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(transparent 50%, white);
      }
    }
    &--active {
      &:after {
        opacity: 0;
      }
    }
  }
}
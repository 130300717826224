html {
  min-height: 100%;
}
body {
  height: 100%;
  min-height: 100vh;
  color: $body-color;
  scroll-behavior: smooth;
  background-color: $body-bg;
  letter-spacing: -0.05rem;
}

#CybotCookiebotDialogPoweredbyCybot, #CybotCookiebotDialogPoweredByText {
  display: none !important;
}

a {
  color: $body-color;
  &:hover {
    color: $dt-dark-grey;
  }
}

#__next {
  height: 100%;
  min-height: 100vh !important;
}

.container-fluid-dt {
  max-width: 1290px;
}

.wrapper-dt {
  display: flex;
  flex-direction: column;
}

.wrapper-dt main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.bg-black {background-color: $dt-black;}
.bg-transparent {background-color: transparent;}
.bg-darkgrey {background-color: $dt-dark-grey;}
.bg-lightgrey {background-color: $dt-light-grey;}
.bg-red {background-color: $dt-red;}

.border-red {
  border-color: $dt-red !important;
}
.border-dark-dt, .border.border-dark-dt {
  border-color: $dt-dark-grey !important;
}

.white { color: $dt-white !important;}
.black { color: $dt-black !important;}
.red {color: $dt-red;}
.lightgrey {color: tint($dt-black,80%);}
.grey {color: #0000008a;}
.darkGrey {color: $dt-dark-grey;}
.green {color: green;}



.bold {@include bold()}

.font13 {@include font-size(13);}
.font20 {@include font-size(20);}
.font23 {@include font-size(23);}
.font25 {@include font-size(25);}
.font30 {@include font-size(30);}


@include respond-to('max-medium') {
  .px-md-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

}
.socialFlags svg:not(:hover) {
  .whiteMod {
    fill : white;
  }
  .transparentMod {
    fill: transparent;
  }
  .blackMod {
    fill : black;
  }
}

.ic-facebook { color : #3b5998; }
.ic-twitter { color : #00aced; }
.ic-linkedin { color :#007bb6; }
.ic-instagram { color : #3f729b; }

.patchBestPrice  {
  border: 6px solid $dt-red;
}

.reset-position {
  right: 0;
  left: 0;
  top: 0;
  bottom: 0
}

/* Form cmd DT */
.puce-cmd { top : 5px;}
.input-dt-cmd {
  max-width: 150px;
  min-height: 48px;
  border-radius: 0;
  text-align: center;
  width: auto;
}

.form-cmd-dt {
  .form-control.is-invalid, .form-control.is-valid {
    padding-right: calc(1em);
    background-image: none ;
  }
  .form-control.is-invalid {
    border: 1px solid red !important;
  }

  .form-group .font20  {
    @include font-size(16);
    @include respond-to('medium') {
      @include font-size(20);
    }
  }
  .lineSelected .qtySelected {
   outline: 3px solid $dt-red;
  }
  .input-file {
    height: 60px;
    border: 1px solid #ccc;
    width: 100%;
  }
  .file-custom input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    border: 4px solid $dt-black;
    opacity: 0;
    cursor:pointer;
  }
  .file-custom {
    width: 100%;
    height: 130px;
    cursor: pointer;
    border: 2px dashed $dt-grey;
    margin-bottom:0;
    &.file-custom-reupload {
      height: 40px;
      border: none;
      margin: 0 !important;
    }
  }
  .file-custom p {
    line-height: 1em;
    text-align: center;
    height: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    span {
      border-bottom: 1px solid transparent !important;
    }
  }

  .file-custom:not(.file-custom-reupload):hover, .file-custom:not(.file-custom-reupload):focus {
    border: 3px dashed $dt-red;
  }
  .file-custom-reupload:hover p span {
    border-bottom: 1px solid $dt-red !important;
  }
}
.filter-red {
  -webkit-filter: invert(40%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(2);
  filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
}

.bg-alpha {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+CjxyZWN0IHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0iI2ZmZiI+PC9yZWN0Pgo8cmVjdCB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIGZpbGw9IiNjY2MiPjwvcmVjdD4KPHJlY3QgeD0iMTAiIHk9IjEwIiB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIGZpbGw9IiNjY2MiPjwvcmVjdD4KPC9zdmc+");
  &:hover {
    background: white;
  }
}
.etape-validate {
  width: 100px;
  height: 50px;
  justify-content: center;
  display: flex;
  bottom: 0;
  padding-top: 10px ;
}
.etape-validate svg {
  background: white;
  border-radius: 50px;
  border: 1px solid;
}
.labelInputUpload {
  max-width: 200px;
  line-height: 1.5em;
}

.img-upload-miniature {
  max-height: 100px;
}

.validateDimensions input {
  border: none;
}
.validateDimensions button, .suppressFile button {
  min-width: 280px;
}

.blocCommande {
  .form-group {
    margin-bottom: 0;
  }
}

.zoomModalContainer button {
  background: $dt-white;
  border: 1px solid;
}
.zoomModalFinesseControl {
  max-width: 90vw;
  .containerImgControl {
    height: 60vh;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+CjxyZWN0IHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0iI2ZmZiI+PC9yZWN0Pgo8cmVjdCB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIGZpbGw9IiNjY2MiPjwvcmVjdD4KPHJlY3QgeD0iMTAiIHk9IjEwIiB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIGZpbGw9IiNjY2MiPjwvcmVjdD4KPC9zdmc+");
    display: flex;
    align-items: center;
    padding: 20px;
    @media (max-width: 425px){
      height: auto;
    }
    .imgControl {
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }
}
.cursor-zoom {
  cursor: zoom-in;
}
.cursor-pointer {
  cursor: pointer;
}

.popOverCharacteristics {
  .popover {
    max-width: 80vw;
  }
  h2, a {
    display: none;
  }
  ul {
    margin: 0;
    padding: 0 0 0 20px;
    li {
      margin-bottom: 10px;
    }
  }
  .popover-inner {
    @include box-shadow(2px, 5px, 5px, tint($dt-black,80%), false);
  }
}
.containerCartDT  {
  min-height: 50vh;
  max-width: none;
  padding-bottom: 60px;
  #legendCartItemTemplate {
    background: #222;
    color: white;
  }
  .btnRemoveItem {
    margin-left: 10px;
  }
  .remisePu {
    background: $dt-red;
    color: white;
    padding: 5px;
    @include rounded(3.3em);
  }
  .itemPuSansDegressivite {
    text-decoration: line-through;
  }
  .cartItem, .paypal-button-color-silver {
    background: $dt-white !important;
  }
  .dateLivraison {
    padding: 0;
    text-align: center;
    @include respond-to('medium') {
      padding: inherit;
      text-align: inherit;
    }
  }
  .mt-md-2, .my-md-2 {
    margin-top: .5rem!important;
  }
}

.hover-underline:hover {
  text-decoration: underline;
}

.line-height-1 {
  line-height: 1em;
}

.slider-wrapper {
  .slick-prev {
    left: 0px;
    z-index:2;
  }
  .slick-next {
    right: 0px;
    z-index:2;
  }
  .slick-prev, .slick-next {
    height: auto;
    background:white;
    width: auto;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    color: $dt-dark-grey;
  }
  .slick-next:hover, .slick-prev:hover {
    cursor: pointer;
    opacity: 0.8;
    color: initial;
    background: scale-color($dt-white, $lightness: 50%);
  }

  .slick-next:before,.slick-prev:before {
    content: none;
  }
  .slick-active svg {
    color: $dt-white;
  }
  .slide-content {
    img {
     max-width: 100%;
      max-height: 55vh;
      @include respond-to('extra-extra-large') {
        width: 1920px;
        max-height: inherit !important;
      }
    }
    font-size: 36px;
    &.slide-24h {
      @include radial-gradient(#402d52,  shade(#402d52,40%));
    }
    &.slide-new-product {
      @include radial-gradient(#1387f3,  shade(#1387f3,40%));
    }
    &.slide-sabuncu {
      @include radial-gradient(#ca2074,  shade(#ca2074,40%));
    }
    &.slide-crazy-product {
      @include radial-gradient(#2ae2ca,  shade(#2ae2ca,40%));
    }
  }
  .slick-dots {
    background-color: transparent !important;
    bottom: 0;
    padding: 10px !important;

    @include respond-to('large') {
      bottom: 20px;
      padding: 30px !important;
    }

    ul {
      padding: 0;
    }
  }
  &:hover {
    .slick-prev, .slick-next {
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    }
  }
}
.letterSpacingEtendu {
  letter-spacing: 0.2rem;
}
.letterSpacingNormal {
   letter-spacing: 0;
 }
.homePatch-bg-1 {
  height: 600px;
  max-height: 60vh;
  margin-right: 0;
  @include respond-to('large') {
    margin-right: 150px;
  }
}

@mixin imgFondPage($imgPageSrc, $bgColorPage) {
  background-size: auto;
  background : url($imgPageSrc) no-repeat  $bgColorPage;
}
.wrapper-dt {
  &.page-about-us {
    background: $dt-white;
  }
  &.page-category-448 {
    background: #fff;
    @include respond-to('large') {
      @include imgFondPage("/images/patch-videos.jpg", #ffffff);
    }
  }
  &.page-category-446 {
    background: #fff;
    @include respond-to('large') {
      @include imgFondPage("/images/patch-446.jpg", #ffffff);
      background: #fff;
    }
  }
  &.page-home {
    background: $dt-white;
    /* animation: animatedBackground 2s cubic-bezier(0.215, 0.610, 0.355, 1.000)  ; */
  }
}

.customBorder {
  border-left: 10px solid $dt-grey;
  margin-right: 30px;
}

.zoomVideo {
  width: 80vw;
  @include respond-to('large') {
    width: 40vw;
  }
  div {
    width: 100%;
    min-height: 50vh;
    @include respond-to('large') {
      min-height: 30vh;
    }
  }
}
.zoomVideoInstitu {
  max-width: 80vw !important;
  width: 80vw !important;
  padding: 0.4rem;
  background: white;
  -webkit-box-shadow: 0 100px 130px -70px #000,-70px 170px 150px 50px black !important;
  box-shadow: 0 100px 130px -70px #000,-70px 170px 150px 50px black !important;
}
.zoomVideoInstituOverlay {
  background: rgba(0,0,0,.8) !important;
}
.zoomVideoInstituCloseBtn {
  display: none;
}

.playVideosContainer {
  &:not(.playVideosContainerMiniatures) {
    min-height: 200px;
  }
  .playVideosIcon {
    position: absolute;
    z-index: 1;
    border-radius: 100%;
    background-color: rgba(0,0,0,0.6);
    padding: 10px 3px;
    &.playVideosIconBottomRight {
      bottom: 30px;
      right: 10px;
    }
  }
  &:not(.playVideosContainerMiniatures):hover {
    &::before {
      background :white;
      width: 100%;
      opacity: 0.1;
    }
    .playVideosIcon {
      opacity: 1;
      background-color: $dt-red;
      @include box-shadow(0, 0, 10px, tint($dt-dark-grey, 80%), false);
    }
  }
}

.video--element {
  min-width: 100vw;
  min-height: 100%;
  height: 100%;
  background: #000;
  position: absolute;
  top: 0;
  z-index: 1000;
  @include respond-to('large') {
    left: 0;
    height: auto;
    width: 100%;
    min-width: 100vw;
    min-height: auto;
  }
}

.about-us-video-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.about-us-video {
  width: 100%;
  height: 80vh;
  top: -1px;
  padding:0 !important;
  z-index: 0;
  @include respond-to('large') {
    height: 50vh;
    top: 0;
    padding: initial !important;
  }
  overflow: hidden;
  position: relative;
}

.about-us-video-texte {
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  background: rgba(0, 0, 0, .2);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1100;
  color: #fff;
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  font-weight: 300;
  text-align: left;
}

.outline-1px {
  outline: 1px solid $dt-black;
}

.arianne a   {
  padding: 0.9rem 0;
}

#cartApp {
  height: 100%;
  .containerCartEmpty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    @include font-size(16);
  }
}

.FormFeedbackDimensionInput {
  min-width: 300px;
  left: 0px
}
.form-cmd-dt {
  display: flex;
  flex-direction: column-reverse;
}
.MoreInformationOnPrices {
  min-width : 100px;
  @include respond-to('medium') {
    min-width : 200px;
  }
}
.react-responsive-modal-root {
  /* in front of chat and all others */
  z-index: 2147483639123;
}
.modalPriceList {
  width: 100%;
  padding: 0;
  max-width: unset;
  margin: 0;
  zoom: 0.4;
  @include respond-to('large') {
    padding: initial;
    width: 90vw;
    zoom: unset;
    margin: 0 auto;
  }
}
.priceListFirstThContainer {
  position: relative;
  .priceListFirstTh {
    position: absolute;
    top:0;
    left: 0;
    clip-path: polygon(0 100%, 0 0, 100% 100%);
    background: white;
    width: 100%;
    height: 100%;
  }
  .priceListFirstThContainerArea {
    position: relative;
    left: 8px;
    z-index: 23;
    text-transform: capitalize;
  }
  & div {
    position: relative;
    z-index: 2;
    padding: 5px;
    color: black;
    text-align: left;
   &.text-right {
      color: white;
     padding-right: 10px;
    }
  }
}

.tablePrices th {
  vertical-align: middle !important;
  text-align: center;
}
.tablePrices td {
  text-align: center;
}

.colorSearchSecondChoice:not(:hover)  {
  opacity: 0.3;
}
.miniPicker {
  zoom: 0.6
}

.line-clamp-6 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 6;
  -webkit-line-clamp: 6;
}
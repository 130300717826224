
@keyframes animatedBackground {
  from {
    background-position: -10vw 0;
  }
  to {
    background-position: 0 0;
  }
}

.tracking-in-expand {
  -webkit-animation: tracking-in-expand 3.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  animation: tracking-in-expand 3.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}
.slide-in-top {
  -webkit-animation: slide-in-top 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-top 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-right {
  -webkit-animation: slide-in-right 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-right 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.text-shadow-drop-bl {
  -webkit-animation: text-shadow-drop-bl 0.6s both;
  animation: text-shadow-drop-bl 0.6s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-2-17 14:15:14
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}


/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}


/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * @animation text-shadow-drop-bl
 * ----------------------------------------
 */
@-webkit-keyframes text-shadow-drop-bl {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: -6px 6px 18px rgba(0, 0, 0, 0.35);
  }
}
@keyframes text-shadow-drop-bl {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: -6px 6px 18px rgba(0, 0, 0, 0.35);
  }
}


.collapse-css-transition {
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.collapse-css-transition {
  position: relative;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
  &--gradient {
    &:after {
      pointer-events: none;
      transition: opacity 300ms;
      will-change: opacity;
      opacity: 1;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(transparent 50%, white);
    }
  }
  &--active {
    &:after {
      opacity: 0;
    }
  }
}
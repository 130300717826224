
.text-underline {
  text-decoration : underline;
}

.img-greyscale {filter: greyscale(1);}

.align-text-top {vertical-align: text-top;}
.align-baseline {vertical-align: baseline;}

.border-2 {
  border-width: 2px!important;
}
.border-3 {
  border-width: 3px!important;
}
